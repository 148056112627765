import { Button, Checkbox, Col, Form, Input, notification, Row, Typography } from 'antd'
import { navigate } from 'hookrouter'
import React, { useEffect, useState } from 'react'
import logo from '../assets/bizerba-logo.png'
import { API_URL } from '../constant'

const { Text } = Typography
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}
const { Title, Paragraph } = Typography

interface AuthData {
  codicecliente: string
  password: string
  remember: boolean
}
const initalStateLoginData = {
  codicecliente: "",
  password: "",
  remember: false
}

export interface ResponseData {
  codice: string
  email: string
  id: string
  isAdmin: boolean
  token: string
}

const LOGIN_URL = API_URL + "/login"

const Login: React.FC = () => {
  
  useEffect(() => {
    const nullableAuthData = localStorage.getItem('account_remember_data')
    if (nullableAuthData !== null && nullableAuthData !== "") {
      const authData: AuthData = JSON.parse(nullableAuthData)
      setLoginData(authData)
    } else setLoginData(initalStateLoginData)
  }, [])

  const saveData = (data: ResponseData, authData: AuthData) => {
    localStorage.setItem('account_data', JSON.stringify(data))
    if (authData.remember) {
      localStorage.setItem('account_remember_data', JSON.stringify(authData))
    } else {
      localStorage.removeItem('account_remember_data')
    }
  }

  const [loginData, setLoginData] = useState<AuthData | undefined>()

  const onFinish = (val: AuthData) => {

    const user = {
      id: val.codicecliente,
      password: val.password
    }

    fetch(LOGIN_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(user),
      redirect: "follow"
    })
    .then(res => res.json())
    .then((res: ResponseData) => {
      saveData(res, val)
      navigate('/dashboard')
    })
    .catch(err => notification.error({ message: "Si è verificato un errore durante il login. Si prega di riprovare", duration: 6 }))
  }

  const onFinishFailed = (err: unknown) => {
    console.log(err)
  }

  const form = loginData && (
    <Form
      {...layout}
      name="basic"
      initialValues={{ 
        remember: loginData?.remember, 
        codicecliente: loginData?.codicecliente,
        password: loginData?.password }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Paragraph style={{ marginBottom: 0, fontWeight: 600 }}>Codice cliente</Paragraph>
      <Form.Item
        style={{ marginBottom: 12 }} 
        name="codicecliente"
        rules={[{ required: true, message: 'Inserire il codice cliente' }]}
      >
        <Input placeholder="Codice cliente"/>
      </Form.Item>

      <Paragraph style={{ marginBottom: 0, fontWeight: 600 }}>Password</Paragraph>
      <Form.Item
        style={{ marginBottom: 12 }} 
        name="password"
        rules={[{ required: true, message: 'Inserire la password' }]}
      >
        <Input.Password placeholder="Password"/>
      </Form.Item>

      <Form.Item>
        <Form.Item name="remember" valuePropName="checked" style={{ marginBottom: 12 }} noStyle>
          <Checkbox>Ricordami</Checkbox>
        </Form.Item>

        <a style={{ float: 'right', color: '#ff4d4f' }} onClick={_ => navigate('/passworddimenticata')} href="#">Password dimenticata?</a>
      </Form.Item>

      <Form.Item>
        <Button style={{ width: '100%' }} type="primary" htmlType="submit">
          Entra
        </Button>
        oppure <a onClick={_ => navigate('/nuovoutente')} href="#">Registra un nuovo utente</a>

      </Form.Item>
    </Form>
  )

  return (

    <Row justify="center" align="middle" style={{ height: '100vh' }}>
      <Col span={12}>
        <Row>
          <Col span={12} offset={6}>
            <Title>Effettua l'accesso</Title>
            {form}
          </Col>
        </Row>
      </Col>

      <Col span={12}>
        <img style={{ maxWidth: '600px' }} src={logo} alt=""/>
      </Col>
    </Row>

  )
}

export default Login