import { Badge, Button, Checkbox, Col, Dropdown, Input, Layout, List, Menu, Modal, PageHeader, Row, Select, Space, Spin, Table, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { API_URL } from '../constant'
import { EllipsisOutlined, FileOutlined, BellOutlined } from '@ant-design/icons'
import { ResponseData } from '../userAction/login'
import { navigate } from 'hookrouter'
import * as datefns from 'date-fns'
import logo from '../assets/bizerba-logo-inverted.png'

const { Content, Sider } = Layout
const { Option } = Select
const { Text } = Typography

type AvailableContent 
  = "default" 
  | "dettaglio_sede" 
  | "strumenti_in_scadenza" 
  | "gestione_clienti"

const colsStrumentiScadenza = [
  { title: "Sede", dataIndex: "nomeSede" },
  { title: "Modello", dataIndex: "descrizione", render: (modello: string) => <span>{modello.split(' - ')[1]}</span> },
  { title: "Descrizione", dataIndex: "descrizione" },
  { title: "Matricola", dataIndex: "matricola" },
  { title: "Reparto", dataIndex: "reparto" },
  { title: "Scadenza VP", dataIndex: "dataVisitaProgrammata", render: (data: string) => <span>{datefns.format(new Date(data), "dd/MM/yyyy")}</span> }
]

const colsClienti = [
  { title: "Codice", dataIndex: "codice" },
  { title: "Ragione sociale", dataIndex: "ragioneSociale" },
  { title: "Città", dataIndex: "citta" },
  { title: "PV", dataIndex: "provincia" },
  { title: "Indirizzo", dataIndex: "indirizzo" },
  { title: "Area competenza", dataIndex: "areaDiCompetenza" },
  { title: "Note riservate", dataIndex: "noteRiservate" },
  { title: "Opzione 4", dataIndex: "", render: (_: string, cliente: Cliente) => <Checkbox disabled checked={cliente.opzioneQuattro} onChange={() => {}} /> },
]

const emptyAccountData: ResponseData = {
  codice: "",
  email: "",
  id: "",
  isAdmin: false,
  token: ""
}

// MODEL

interface Sede {
  id: number
  citta: string
  provincia: string
  indirizzo: string
  nomeCompleto: string
}

interface Strumento {
  id: number
  descrizione: string
  matricola: string
  classe: string
  noteStampabili: string
  dataVisitaProgrammata: string
}

interface StrumentoScadenza {
  sede: Sede
  matricola: string
  descrizione: string
  classe: string
  dataVisitaProgrammata: string
  ragioneSociale: string
  nomeSede?: string
}

interface Cliente {
  codice: number
  ragioneSociale: string
  citta: string
  provincia: string
  indirizzo: string
  areaCompetenza: string
  opzioneQuattro: boolean
  noteRiservate: string
}

// Metodo per indicare se una VP è o meno in scadenza. La data viene colorata in verde 
// fino all'ultimo giorno del suo mese di validità, diventando di colore rosso dal 
// primo giorno del mese successivo fino alla scadenza.
// e.g.
// Strumento in scadenza ad ottobre. Oggi 11 ottobre. Colore verde fino al 31 ottobre. Colore rosso dal 1 novembre
// 27/02/2020 == verde
function isLate(v: string): boolean {
  const date = new Date(v);
  const today = new Date();
  const isYearBefore = date.getFullYear() < today.getFullYear()
  const isSameYear = date.getFullYear() === today.getFullYear()
  const isMonthBefore = date.getMonth() < today.getMonth()
  return isYearBefore || isSameYear && isMonthBefore 
}

const MenuOptions: React.FC<{ accountData: ResponseData }> = ({ accountData }) => {
  const handleLogoutClick = () => {
    localStorage.removeItem('account_data')
    navigate('/')
  }

  function info() {
    Modal.info({
      title: 'Informazioni account',
      content: (
        <div style={{ marginTop: 30 }}>
          {accountData.isAdmin ? (
            <p><strong>ACCOUNT AMMINISTRATORE</strong></p>
          ) : (
            <p style={{ marginBottom: 5 }}><strong>Codice utente:</strong> <span>{accountData.codice}</span></p>
          )}
          <p style={{ marginBottom: 5 }}><strong>Email:</strong> <span>{accountData.email}</span></p>
          <p style={{ marginBottom: 5 }}><strong>Username:</strong> <span>{accountData.id}</span></p>
        </div>
      ),
      onOk() {}
    })
  }

  return (
    <Menu>
      <Menu.Item onClick={_ => info()}>
        <span>
          Account
        </span>
      </Menu.Item>
      <Menu.Item onClick={handleLogoutClick}>
        <Text type="danger">Logout</Text>
      </Menu.Item>
    </Menu>
  ) 
}

const DropdownMenu: React.FC<{ accountData: ResponseData }> = ({ accountData }) => {
  return (
    <Dropdown className="custom-navbar-more-button" key="more" trigger={[ 'click' ]} overlay={<MenuOptions accountData={accountData} />}>
      <Button
        style={{
          border: 'none',
          padding: 0
        }}
      >
        <EllipsisOutlined style={{ fontSize: 25 }} />
      </Button>
    </Dropdown>
  )
}

const Skeleton: React.FC = () => {

  const [accountData, setAccountData] = useState<ResponseData>(emptyAccountData)

  function noteRiservate() {
    Modal.info({
      title: 'Note riservate utente',
      content: (
        <div style={{ marginTop: 30 }}>
          {clienteLoggato && clienteLoggato.noteRiservate !== "" ? clienteLoggato.noteRiservate : "Nessuna nota riservata presente."}
        </div>
      ),
      onOk() {}
    })
  }

  const [clienteLoggato, setClienteLoggato] = useState<Cliente>()
  useEffect(() => {
    const _accountData = localStorage.getItem('account_data')
    if (_accountData !== null) {
      const ac: ResponseData = JSON.parse(_accountData)
      setAccountData(ac)

      if (!ac.isAdmin) {
        fetch(`${API_URL}/clienti`, {
          headers: { 'Authorization': `Bearer ${accountData.token}`}
        })
        .then(res => res.json())
        .then((res: Cliente[]) => {
          const cliente: Cliente | undefined = res.find(c => c.codice === Number(ac.codice))
          setClienteLoggato(cliente)
        })
      }
    }
  }, [])

  const [activeContent, setActiveContent] = useState<AvailableContent>("default")

  const renderContent = () => {
    switch (activeContent) {
      case "default":
        return !accountData.isAdmin ? <p className="seleziona-una-sede">Selezionare una sede</p> : <></>
      case "dettaglio_sede":
        return (
          <>
            <div style={{ maxWidth: 500, marginBottom: 10 }}>
              <Input
                placeholder="Cerca uno strumento"
                onChange={handleSearchStrumenti}
                allowClear
                addonAfter={
                  <Select style={{ minWidth: 150 }} defaultValue="Descrizione" onChange={v => setSearchPropStrumenti(v)}>
                    <Option value="descrizione">Descrizione</Option>
                    <Option value="matricola">Matricola</Option>
                    <Option value="classe">Reparto</Option>
                  </Select>
                }
              />
            </div>
            <Table
              size="small"
              columns={colsStrumenti}
              dataSource={searchResultStrumenti.map((str, i) => ({...str, key: i }))}
              pagination={{
                defaultPageSize: 15
              }}
            />
          </>
        )
      case "strumenti_in_scadenza":
        return strumentiScadenza.loading ? (
          <Row justify="center" align="middle" style={{ height: 'calc(100vh - 108px)' }}>
            <Col>
              <Spin size="large"/>
            </Col>
          </Row>
        ) : (
          <>
            <div style={{ maxWidth: 500, marginBottom: 10 }}>
              <Input
                placeholder="Cerca uno strumento"
                onChange={handleSearchStrumentiScadenza}
                allowClear
                addonAfter={
                  <Select style={{ minWidth: 150 }} defaultValue="Sede" onChange={v => setSearchPropStrumentiScadenza(v)}>
                    <Option value="descrizione">Descrizione</Option>
                    <Option value="matricola">Matricola</Option>
                    <Option value="reparto">Reparto</Option>
                    <Option value="nomeSede">Sede</Option>
                  </Select>
                }
              />
              {/* <RangePicker onChange={(d) => console.log(d?.map(dd => dd?.format("DD/MM/yyyy")))} /> */}
            </div>
            <Table
              size="small"
              columns={colsStrumentiScadenza}
              dataSource={searchResultStrumentiScadenza.map(str => ({...str, nomeSede: str.sede.citta + " - " + str.sede.indirizzo})).map((str, i) => ({...str, key: i }))}
              pagination={{
                defaultPageSize: 15
              }}
            />
          </>
        )
      case "gestione_clienti":
        return clienti.loading ? (
          <Row justify="center" align="middle" style={{ height: 'calc(100vh - 108px)' }}>
            <Col>
              <Spin size="large"/>
            </Col>
          </Row>
        ) : (
          <>
            <div style={{ maxWidth: 500, marginBottom: 10 }}>
              <Input
                placeholder="Cerca un cliente"
                onChange={handleSearchClienti}
                allowClear
                addonAfter={
                  <Select style={{ minWidth: 150 }} defaultValue="Codice" onChange={v => setSearchPropClienti(v)}>
                  <Option value="areaCompetenza">Area competenza</Option>
                    <Option value="citta">Città</Option>
                    <Option value="codice">Codice</Option>
                    <Option value="indirizzo">Indirizzo</Option>
                    <Option value="noteRiservate">Note riservate</Option>
                    <Option value="provincia">Provincia</Option>
                    <Option value="ragioneSociale">Ragione sociale</Option>
                  </Select>
                }
              />
            </div>
            <Table
              size="small"
              columns={colsClienti}
              dataSource={searchResultClienti.map((str, i) => ({...str, key: i }))}
              pagination={{
                defaultPageSize: 15
              }}
            />
          </>
        )
      default:
        break
    }
  }

  const colsStrumenti = [
    { title: "Modello", dataIndex: "descrizione", render: (modello: string) => <span>{modello.split(' - ')[1]}</span> },
    { title: "Matricola", dataIndex: "matricola" },
    { title: "Descrizione", dataIndex: "descrizione" },
    { title: "Reparto", dataIndex: "classe" },
    { title: "Data ultima VP", dataIndex: "noteStampabili" },
    { title: "Data scadenza", dataIndex: "dataVisitaProgrammata", render: (data: string) => {
      return (<span className={isLate(data) ? 'is-late' : 'on-time'}>{datefns.format(new Date(data), "dd/MM/yyyy")}</span>)
    }},
    { title: "PDF", dataIndex: "", key: "x", render: (_: unknown, record: Strumento) => <FileOutlined onClick={_ => window.open('https://www.soundczech.cz/temp/lorem-ipsum.pdf')} /> }
  ]

  // STRUMENTI IN SCADENZA

  const [strumentiScadenza, setStrumentiScadenza] = useState<{ value: StrumentoScadenza[], loading: boolean }>({ value: [], loading: false })
  useEffect(() => {
    if (activeContent === "strumenti_in_scadenza") {
      setStrumentiScadenza({...strumentiScadenza, loading: true})

      const predicate = accountData.isAdmin ? '' : '/' + accountData.codice
      const url = `${API_URL}/scadenza${predicate}`

      fetch(url, {
        headers: { 'Authorization': `Bearer ${accountData.token}`}
      })
      .then(res => res.json())
      .then((res: StrumentoScadenza[]) => { 
        setSearchResultStrumentiScadenza(res)
        setStrumentiScadenza({
          value: res.map(str => ({...str, nomeSede: str.sede.citta + " - " + str.sede.indirizzo})), 
          loading: false 
        })})
    }
  }, [activeContent])

  const [searchPropStrumentiScadenza, setSearchPropStrumentiScadenza] = useState<string>("nomeSede")
  const [searchTermStrumentiScadenza, setSearchTermStrumentiScadenza] = useState("")
  const [searchResultStrumentiScadenza, setSearchResultStrumentiScadenza] = useState(strumentiScadenza.value)
  const handleSearchStrumentiScadenza = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
  setSearchTermStrumentiScadenza(e.target.value)

  useEffect(() => {
    if (searchPropStrumentiScadenza !== "") {
      const res = strumentiScadenza.value.filter((obj: any) =>
        obj[searchPropStrumentiScadenza].toLowerCase().includes(searchTermStrumentiScadenza.toLowerCase())
      )
      setSearchResultStrumentiScadenza(res)
    }
  }, [searchTermStrumentiScadenza])

  // SEDI

  const [sedi, setSedi] = useState<Sede[]>([])
  const [sedeSelezionata, setSedeSelezionata] = useState<Sede | undefined>()
  useEffect(() => {
    if (accountData.codice !== "" && !accountData.isAdmin) {
      fetch(`${API_URL}/sedi/${accountData.codice}`, {
        headers: { 'Authorization': `Bearer ${accountData.token}`}
      })
      .then(res => res.json())
      .then((res: Sede[]) => {
        const _sedi = res.map(r => ({...r, nomeCompleto: `${r.citta} - ${r.indirizzo}`}))
        setSearchResultSedi(_sedi)
        setSedi(_sedi)
      })
    }
  }, [accountData.codice])

  const [searchTermSedi, setSearchTermSedi] = useState("")
  const [searchResultSedi, setSearchResultSedi] = useState(sedi)
  const handleSearchSedi = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setSearchTermSedi(e.target.value)

  useEffect(() => {
    const res = sedi.filter(obj =>
      obj["nomeCompleto"].toLowerCase().includes(searchTermSedi.toLowerCase())
    )
    setSearchResultSedi(res)
  }, [searchTermSedi])

  // STRUMENTI PER SEDE

  const [strumenti, setStrumenti] = useState<Strumento[]>([])
  useEffect(() => {
    if (sedeSelezionata !== undefined) {
      fetch(`${API_URL}/articoli/${sedeSelezionata.id}`, {
        headers: { 'Authorization': `Bearer ${accountData.token}`}
      })
      .then(res => res.json())
      .then((res: Strumento[]) => { setSearchResultStrumenti(res); setStrumenti(res) })
    }
  }, [sedeSelezionata])

  const [searchPropStrumenti, setSearchPropStrumenti] = useState<string>("descrizione")
  const [searchTermStrumenti, setSearchTermStrumenti] = useState("")
  const [searchResultStrumenti, setSearchResultStrumenti] = useState(strumenti)
  const handleSearchStrumenti = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
    setSearchTermStrumenti(e.target.value)

  useEffect(() => {
    if (searchPropStrumenti !== "") {
      const res = strumenti.filter((obj: any) =>
        obj[searchPropStrumenti].toLowerCase().includes(searchTermStrumenti.toLowerCase())
      )
      setSearchResultStrumenti(res)
    }
  }, [searchTermStrumenti])

  // CLIENTI

  const [clienti, setClienti] = useState<{ value: Cliente[], loading: boolean}>({ value: [], loading: false})
  useEffect(() => {
    setClienti({...clienti, loading: true})
    if (activeContent === "gestione_clienti") {
      fetch(`${API_URL}/clienti`, {
        headers: { 'Authorization': `Bearer ${accountData.token}`}
      })
      .then(res => res.json())
      .then((res: Cliente[]) => { 
        setSearchResultClienti(res)
        setClienti({ value: res, loading: false })
      })
    }
  }, [activeContent])

  const [searchPropClienti, setSearchPropClienti] = useState<string>("codice")
  const [searchTermClienti, setSearchTermClienti] = useState("")
  const [searchResultClienti, setSearchResultClienti] = useState(clienti.value)
  const handleSearchClienti = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
  setSearchTermClienti(e.target.value)

  useEffect(() => {
    const res = clienti.value.filter((obj: any) =>
      obj[searchPropClienti].toString().toLowerCase().includes(searchTermClienti.toLowerCase())
    )
    setSearchResultClienti(res)
  }, [searchTermClienti])

  return (
    <Layout>
      <PageHeader 
        title={
          <img style={{ maxWidth: 220 }} src={logo} />
        }
        className="custom-page-header"
        extra={
          <Space size="large">
            {clienteLoggato && (
              <Badge dot count={clienteLoggato.noteRiservate === "" ? 0 : 1}>
                <BellOutlined style={{ fontSize: 20 }} onClick={_ => noteRiservate()} />
              </Badge>
            )}
          
            <DropdownMenu accountData={accountData} />
          </Space>
        }
      />

      {/* SIDEBAR */}

      <Layout>
        <Sider 
          theme="light" 
          width={300} 
          style={{ height: 'calc(100vh - 72px)', overflowY: 'scroll' }}>
          <List
            
            header={
              <>
                <small style={{ display: 'block', textAlign: 'center'}}>Copyright © 2021 Futurelab </small>
                <div style={{ padding: 12 }}>
                  {(activeContent === "default" || activeContent === "dettaglio_sede") ? (
                    <>
                      <Button 
                        block 
                        danger 
                        type="primary" 
                        htmlType="button" 
                        style={{ marginBottom: 12 }}
                        onClick={_ => setActiveContent("strumenti_in_scadenza")}>
                        Strumenti in scadenza
                      </Button>
                      {accountData.isAdmin && (
                        <Button 
                        block 
                        htmlType="button" 
                        style={{ marginBottom: 12 }}
                        onClick={_ => setActiveContent("gestione_clienti")}>
                        Gestione clienti
                      </Button>
                      )}
                    </>
                  ) : (
                    <Button 
                      block 
                      danger 
                      type="primary" 
                      htmlType="button" 
                      style={{ marginBottom: 12 }}
                      onClick={_ => setActiveContent("default")}>
                      Indietro
                    </Button>
                  )}
                  {((activeContent === "default" || activeContent === "dettaglio_sede") && !accountData.isAdmin) && <Input allowClear onChange={handleSearchSedi} placeholder="Cerca una sede" />}
                </div>
              </>
            }
            dataSource={(activeContent === "default" || activeContent === "dettaglio_sede") ? searchResultSedi : []}
            renderItem={sede => (
              <List.Item 
                className="custom-list" 
                style={{ padding: '8px 12px' }}
                onClick={_ => { setSedeSelezionata(sede); setActiveContent("dettaglio_sede")}}>
                {`${sede.citta} - ${sede.indirizzo}`}
              </List.Item>
            )}
          />
        </Sider>

        {/* MAIN CONTENT */}

        <Layout>
          <Content style={{ padding: 18 }}>
            {renderContent()}
          </Content>
        </Layout>
      </Layout>

    </Layout>
  )
}

export default Skeleton