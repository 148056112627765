import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import * as serviceWorker from './serviceWorker'
import 'antd/dist/antd.css'
import { navigate, useRoutes } from 'hookrouter'
import Login from './userAction/login'
import Skeleton from './skeleton/skeleton'
import { Button, Col, ConfigProvider, Result, Row } from 'antd'
import itIt from 'antd/es/locale/it_IT'
import NuovoUtente from './userAction/nuovoUtente'
import PasswordDimenticata from './userAction/passwordDimenticata'
import PasswordReset from './userAction/passwordReset'
import logo from './assets/bizerba-logo.png'

const routes = {
  '/': () => <Login />,
  '/nuovoutente': () => <NuovoUtente />,
  '/passworddimenticata': () => <PasswordDimenticata />,
  '/passwordreset/:token': ({ token }: any) => <PasswordReset token={token} />,
  '/dashboard': () => <Skeleton />
}

const App: React.FC = () => {
  const routeRes = useRoutes(routes)

  return routeRes || (
    <Row justify="center" align="middle" style={{ height: '100vh' }}>
      <Col span={12}>
        <Row>
          <Col span={12} offset={6}>
            <Result
              status="404"
              title="404"
              subTitle="Ci scusiamo per l'inconveniente, la pagina che stai visitando sembra non essere più disponibile"
              extra={<Button onClick={_ => navigate('/')} type="primary">Torna al login</Button>}
            />
          </Col>
        </Row>
      </Col>

      <Col span={12}>
        <img style={{ maxWidth: '600px' }} src={logo} alt=""/>
      </Col>
    </Row>
  )
}

ReactDOM.render(<ConfigProvider locale={itIt}><App /></ConfigProvider>, document.getElementById('root'))

serviceWorker.unregister()
