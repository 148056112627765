import React, { useState } from 'react'
import { Button, Col, Form, Input, notification, Result, Row, Typography } from 'antd'
import logo from '../assets/bizerba-logo.png'
import { navigate } from 'hookrouter'
import { API_URL } from '../constant'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}
const { Title, Paragraph } = Typography

interface NewUserData {
  codiceCliente: string
  email: string
  password: string
}

const PASSWORD_RESET_URL = API_URL + "/nuovo_utente"

const NuovoUtente: React.FC = () => {
  
  const [submitted, setSubmitted] = useState(false)

  const onFinish = (data: NewUserData) => {
    fetch(PASSWORD_RESET_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data),
      redirect: "follow"
    })
    .then(res => {
      if (res.ok) {
        setSubmitted(true)
        setTimeout(() => {
          navigate('/')
        }, 4000)
      }
    })
    .catch(err => notification.error({ message: "Si è verificato un errore durante l'aggiornamento della password. Si prega di riprovare", duration: 6 }))
  }

  const onFinishFailed = (err: unknown) => {
    console.log(err)
  }

  const form = (
    <Form
      {...layout}
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Paragraph style={{ marginBottom: 0, fontWeight: 600 }}>Codice cliente</Paragraph>
      <Form.Item
        style={{ marginBottom: 12 }} 
        name="codicecliente"
        rules={[{ required: true, message: 'Inserire il codice cliente' }]}
      >
        <Input placeholder="Codice cliente"/>
      </Form.Item>

      <Paragraph style={{ marginBottom: 0, fontWeight: 600 }}>Email</Paragraph>
      <Form.Item
        style={{ marginBottom: 12 }} 
        name="email"
        rules={[{ required: true, message: 'Inserire una email' }]}
      >
        <Input type="email" placeholder="Email"/>
      </Form.Item>

      <Paragraph style={{ marginBottom: 0, fontWeight: 600 }}>Password</Paragraph>
      <Form.Item
        style={{ marginBottom: 12 }} 
        name="password"
        rules={[{ required: true, message: 'Inserire la password' }]}
      >
        <Input.Password placeholder="Password"/>
      </Form.Item>

      <Form.Item>
        
        <Button danger style={{ marginRight: 10 }} type="link" htmlType="button" onClick={_ => navigate('/')}>
          Annulla
        </Button>

        <Button type="primary" htmlType="submit">
          Registra
        </Button>
      </Form.Item>
    </Form>
  )

  return (
    <Row justify="center" align="middle" style={{ height: '100vh' }}>
      <Col span={12}>
        <Row>
          <Col span={12} offset={6}>
            {submitted ? (
              <Result
                status="success"
                title="Utente registrato con successo"
                subTitle="Fra qualche istante verrai reindirizzato alla pagina di login!"
              />
            ) : (
              <>
                <Title>Registra un nuovo utente</Title>
                {form}
              </>
            )}
          </Col>
        </Row>
      </Col>

      <Col span={12}>
        <img style={{ maxWidth: '600px' }} src={logo} alt=""/>
      </Col>
    </Row>
  )
}

export default NuovoUtente