import React, { useState } from 'react'
import { Button, Col, Form, Input, notification, Result, Row, Typography } from 'antd'
import { navigate } from 'hookrouter'
import logo from '../assets/bizerba-logo.png'
import { API_URL } from '../constant'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}
const { Title, Paragraph } = Typography

interface NewPasswordData {
  email: string
}

const PASSWORD_DIMENTICATA_URL = API_URL + "/password_dimenticata"

const PasswordDimenticata: React.FC = () => {
  
  const [submitted, setSubmitted] = useState(false)
  
  const onFinish = (data: NewPasswordData) => {
    fetch(PASSWORD_DIMENTICATA_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data),
      redirect: "follow"
    })
    .then(res => {
      if (res.ok) {
        setSubmitted(true)
        setTimeout(() => {
          navigate('/')
        }, 4000)
      }
    })
    .catch(err => notification.error({ message: "Si è verificato un errore durante l'invio dell'email. Si prega di riprovare", duration: 6 }))
  }

  const onFinishFailed = (err: unknown) => {
    console.log(err)
  }

  const form = (
    <Form
      {...layout}
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Paragraph style={{ marginBottom: 0, fontWeight: 600 }}>Email</Paragraph>
      <Form.Item
        style={{ marginBottom: 12 }} 
        name="email"
        rules={[{ required: true, message: 'Inserire una email' }]}
      >
        <Input placeholder="Email"/>
      </Form.Item>

      <Form.Item>
        <Button danger style={{ marginRight: 10 }} type="link" htmlType="button" onClick={_ => navigate('/')}>
          Annulla
        </Button>

        <Button type="primary" htmlType="submit">
          Invia
        </Button>

      </Form.Item>
    </Form>
  )

  return (

    <Row justify="center" align="middle" style={{ height: '100vh' }}>
      <Col span={12}>
        <Row>
          <Col span={12} offset={6}>
            {submitted ? (
              <Result
                status="success"
                title="Richiesta inviata con successo"
                subTitle="Controlla la tua casella di posta e segui il link che trovi per impostare la nuova password!"
                extra={
                  <Button type="primary" htmlType="button" onClick={_ => navigate('/')}>
                    Vai al login
                  </Button>
                }
              />
            ) : (
              <>
                <Title>Recupera la password</Title>
                {form}
              </>
            )}
          </Col>
        </Row>
      </Col>

      <Col span={12}>
        <img style={{ maxWidth: '600px' }} src={logo} alt=""/>
      </Col>
    </Row>

  )
}

export default PasswordDimenticata